import React from 'react';
import Layout from 'components/layout';
import Footer from 'components/footer';
import SectionTitle from 'components/sections/title';
import styled from 'styled-components';

const HeroTitle = ({ children }) => (
  <h2 className="title-quote title-dark text-white mb-3">{children}</h2>
);

const WaveShape = ({ color }) => (
  <div className="position-relative">
    <div className="wave-shape">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path
          fill={color}
          fillOpacity="1"
          d="M0,128L26.7,149.3C53.3,171,107,213,160,218.7C213.3,224,267,192,320,192C373.3,192,427,224,480,213.3C533.3,203,587,149,640,138.7C693.3,128,747,160,800,170.7C853.3,181,907,171,960,149.3C1013.3,128,1067,96,1120,117.3C1173.3,139,1227,213,1280,202.7C1333.3,192,1387,96,1413,48L1440,0L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
);

const HighlightedPrimary = styled.span`
  color: #313b97;
`;

const HeroSection = ({ title, text }) => (
  <section
    className="section-xl full-height bg-home overflow-hidden w-100"
    style={{
      backgroundColor: '#ffffff',
      backgroundImage:
        'radial-gradient( circle farthest-corner at -15% -15%,  rgba(0,149,218,0.85) 9.9%, rgba(56,80,114,1) 100.3% )',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-8">
          <div className="title-heading mt-4">
            <span className="text-left">
              <HeroTitle>
                <i>
                  Everything should be made as simple as possible, but not
                  simpler.
                </i>
              </HeroTitle>
            </span>
            <span className="text-right">
              <HeroTitle>
                <i>— Albert Einstein</i>
              </HeroTitle>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Section1 = () => (
  <React.Fragment>
    <SectionTitle>
      Throughout our daily job there exist two important{' '}
      <HighlightedPrimary>Principles</HighlightedPrimary>
    </SectionTitle>
    <div className="row align-items-center pt-5 pb-5">
      <div className="col-12 col-md-5">
        <p className="card-title text-center text-dark">
          Enrich the business of our clients by delivering the high quality
          software for them
        </p>
      </div>
      <div className="col-12 col-md-2 m-auto text-center">
        <HighlightedPrimary>
          <i className="mdi mdi-ampersand font-size-10"></i>
        </HighlightedPrimary>
      </div>
      <div className="col-12 col-md-5">
        <p className="card-title text-center text-dark">
          Receive pleasure from what we are doing in our work
        </p>
      </div>
    </div>
  </React.Fragment>
);

const QualityItem = ({ children }) => (
  <p className="card-title text-center text-dark">{children}</p>
);

const Section2 = () => (
  <React.Fragment>
    <div className="mt-5">
      <SectionTitle>
        <HighlightedPrimary>Qualities</HighlightedPrimary> we value and
        cultivate within the Team
      </SectionTitle>
      <div className="pt-5 pb-5">
        <div className="row align-items-center">
          <div className="col-12 col-md-5">
            <QualityItem>Responsibility</QualityItem>
            <QualityItem>Perseverance</QualityItem>
            <QualityItem>Persistence</QualityItem>
            <QualityItem>Self Discipline</QualityItem>
            <QualityItem>Growth Mindset</QualityItem>
          </div>
          <div className="col-12 col-md-2 text-center">
            <HighlightedPrimary>
              <i className="mdi mdi-check-decagram text-success font-size-10"></i>
            </HighlightedPrimary>
          </div>
          <div className="col-12 col-md-5">
            <QualityItem>Openness</QualityItem>
            <QualityItem>Predictability</QualityItem>
            <QualityItem>Ability to listen</QualityItem>
            <QualityItem>Treating people well</QualityItem>
            <QualityItem>Positive vibes</QualityItem>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const ValuesPage = ({ data }) => (
  <Layout>
    <HeroSection />
    <WaveShape color="#f8f9fc" />
    <section className="section-sm bg-light">
      <div className="container">
        <Section1 />
        <Section2 />
      </div>
    </section>
    <Footer />
  </Layout>
);

export default ValuesPage;